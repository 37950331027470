load-screen.animated {
  font-family: 'Baloo Da 2', cursive;
  background-image: url('/src/Load-Save-Background.jpg');
  color: #384464;
}

load-screen.animated > h2:nth-child(2) {
  text-align: start;
  margin-left: 5%;
  margin-top: 5%;
  font-size: 3vw;
  font-weight: 700;
}

load-screen.animated > h2:nth-child(2)::after {
  content:"Adventure";
  margin-left: 1%;
}
load-screen.animated > div:nth-child(3) {
  margin-left: -1%;
  margin-top: 2%;
}
load-screen.animated > div:nth-child(3) > h3:nth-child(1) {
  display:none;
}
div.padded:nth-child(2) {
  width:50%;
}

div.padded:nth-child(2) > slot-container:nth-child(1) > save-slot {
  height: 23vh;
  float: left;
  width: 32%;
  margin-right: 5%;
  margin-bottom: 5%;
  border-radius: 20px;
}
/* div.padded:nth-child(2) > slot-container:nth-child(1) > save-slot:hover {
  width: 50%;
  height: 30%;
  transition: ease-in-out 0.5s;
} */

div.padded:nth-child(2) > slot-container:nth-child(1) > save-slot > div {
  height:100%;
  border-radius: 20px;
}

div.padded:nth-child(2) > slot-container:nth-child(1) > save-slot > figcaption { 
  display:none;
}

div.padded:nth-child(2) > slot-container:nth-child(1) > save-slot > button {
  visibility: hidden;
  color: rgb(56, 68, 100, 0.5);
  font-size: 1vw;
  width: auto;
  left: 0;
  top:unset;
  bottom: -2vh;
}
div.padded:nth-child(2) > slot-container:nth-child(1) > save-slot > button::after {
  visibility: visible;
  content:"Delete";
}


div.padded:nth-child(2) > slot-container:nth-child(1) > save-slot > small {
  position: absolute;
  top: -12%;
  font-size: 1vw;
  background: transparent;
  color: #384464;
  font-weight: 600;
  font-style: normal;
}

[data-screen]:not([data-screen="game"]) [data-action="back"] {
  bottom: 0;
  margin-left: 5%;
  width: 20%;
  background: transparent;
  color: #384464;
  border: hidden;
  justify-content: left;
  font-size: 1.5vw;
  margin-bottom: 3%;
}

load-screen.animated > button:nth-child(1):after {
  content: 'BACK TO GAME';
}

load-screen.animated > button:nth-child(1):hover:after {
  font-size:1.8vw;
}
load-screen.animated > button svg {
  display:none;
}
