[message="Confirm"]  {
  background-color:rgb(57, 69, 101,0.6);
  z-index: 2000;
}

[message="Confirm"]  > div:nth-child(1) {
  width: 40vw;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #F0EEE1;
  border-radius: 20px;
}

[message="Confirm"]  > div:nth-child(1) > div:nth-child(2) {
  display: flex;
  justify-content: space-evenly;
}

[message="Confirm"]  > div:nth-child(1) > p:nth-child(1) {
  visibility: hidden;
}
[message="Confirm"]  > div:nth-child(1) > p:nth-child(1)::after {
  visibility: visible;
  content: "Are you sure you want to quit?";
  display: inherit;
  color: #384464;
  font-size: 1vw;
  font-weight: 600;
  padding-bottom: 5%;
}

[message="Confirm"] > div:nth-child(1) > div:nth-child(2) button {
  background:#FCD62F;
  border-radius: 42px;
  width: 11vw;
  height: 7vh;
  font-size: 1.5vw;
  color: #364467;
  border:hidden;
}


[message="Confirm"] > div:nth-child(1) > div:nth-child(2) button:hover {
  color: #FCD62F;
  background-color:#364467;
  border:hidden;
}