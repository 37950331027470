.hand-animation {
  animation: rubEyes 1s infinite;
  animation-direction: alternate;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}

@keyframes rubEyes {
  0%{
    -webkit-transform: rotate(-1deg);
    transform: rotate(-1deg);
  }
  20% {
      -webkit-transform: rotate(2deg);
      transform: rotate(2deg);
  }
  30% {
      -webkit-transform: rotate(1deg);
      transform: rotate(1deg);
  }
  40% {
      -webkit-transform: rotate(-1deg);
      transform: rotate(-1deg);
  }
  40% {
    -webkit-transform: rotate(3deg);
  transform: rotate(3deg);
  }
  60% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  80% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  100% {
      -webkit-transform: rotate(3deg);
      transform: rotate(3deg);
  }
}