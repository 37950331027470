/* choices */
choice-container {
  z-index: 102;
  max-height: 100%;
  overflow-y: auto;
  height: 100%;
  width: 100%!important;
  justify-content: center;
  margin: 0 auto;
  flex-wrap: wrap;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  position: absolute;
  background-color:rgb(57, 69, 101,0.6);
}

choice-container > [data-content="wrapper"] {
  flex-direction: row;
  align-items: center;
  width: 80%;
  justify-content: space-around;
}


choice-container button {
  background-color: #F0EEE1;
  font-size: 1.8vw;
  font-family: 'Baloo Da 2', cursive;
  color: #384464;
  width: 30vw;
  height: 20vh;
  border: hidden;
}
choice-container button:hover {
  color: #364467;
  background-color:#FFEDAB;
  fill:#FFEDAB;
  border:hidden;
}