/* kid's background animation */
game-screen.animated > div:nth-child(1) {
  overflow: hidden;
}

.board-animate {
  background-position: 0%;
  background-repeat: no-repeat;
  height:100%;
  animation: linear forwards;
  animation-name: walk;
  animation-duration: 10s;
  width: 178%;
  z-index:100
}
@keyframes walk {
  0%{
    transform: translateX(0%);
  }
  20%{
    transform: translateX(0%);
  }
  40%{
    transform: translateX(-10%);
  }
  100%{
    transform: translateX(-40%);
  }
  }
.plant-animate {
  margin-top: 14vh;
  margin-left: 1%;
  left: 1%;
  position: absolute;
  background-position: 0%;
  background-repeat: no-repeat;
  animation: linear forwards;
  animation-name: follow;
  animation-duration: 10s;
  z-index:102;
  width: 178%;
}
@keyframes follow {
  0%{
    transform: translateX(0%);
    bottom: -63%;
    max-width: 178%
  }
  20%{
    transform: translateX(0%);
    bottom: -63%;
    left:0%;
    max-width: 178%
  }
  40%{
    transform: translateX(-10%);
    bottom: -63%;
    left:0%;
    max-width: 178%
  }
  100%{
    transform: translateX(-40%);
    bottom: -63%;
    left:0%;
    max-width: 178%
  }
  }
/* kid animation */
.kid-animate {
  margin-inline-start: inherit;
  width: 19vw;
  height: 27vw;
  position: absolute;
  animation: run 20s linear forwards,
            kid_move 8s steps(10) infinite;         
  z-index:101;
  object-fit: cover;
  object-position: 0%;
}
@keyframes kid_move{
  100%{
    object-position:111%;
  }
}
@keyframes run {
  0% {
    bottom:-40%;
    left: -110%;
  }
  50% {
    bottom:-40%;
    left: 90%;
  }
  100% {
    bottom:-40%;
    left: 110%;   
  }
  }
