[data-component="message-modal"] {
    overflow: hidden;
    z-index:110;
    pointer-events: none;
}
[data-component="message-modal"] div   {
  width: 100%;
  /* margin-bottom: -45%; */
  height: 100%;
  overflow: hidden;
  background-color: #384464;
  color: #F0EEE1;
  font-family: 'Livvic', sans-serif;
}
[data-component="message-modal"] div div {
  align-items: center;
  display: flex;
  flex-direction: column;
}
[data-component="message-modal"] > div:nth-child(1) > div:nth-child(1) > p:nth-child(2){
  margin-top: 13%;
  font-size: 1.5vw;
  width: 55vw;
  display: flex;
  font-family: Livvic;
  font-style: normal;
  font-weight: 400;
  line-height: 2.8645vw;
  letter-spacing: 0.05vw;
  text-align: center;
}
[data-component="message-modal"] div div h3 {
  width: 100%;
  justify-content: center;
  display: flex;
  
}
[data-component="message-modal"] > div:nth-child(1) > div:nth-child(1) > h3:nth-child(1) > img:nth-child(1){
  width: 12vw;
}
[data-component="message-modal"] > div:nth-child(1) > div:nth-child(1) > p:nth-child(3){
  font-size: 2.55vw;
  width: 49vw;
  font-style: normal;
  font-weight: 600;
  line-height: 4.0625vw;
  letter-spacing: 0em;
  text-align: center;
}

[data-component="message-modal"] button {
  position: absolute;
  visibility: hidden;
}

[data-component="message-modal"] button::after{
  content:"continue";
  visibility: visible;
  text-transform: uppercase;
  position: fixed;
  width: 11vw;
  height: 8vh;
  background: #F8DD67;
  border-radius: 16px;
  font-size: 1.1vw;
  font-weight: 400;
  color: #AA7900;
  box-shadow: inset 0px 2px 9px #ffeea5;
  left: 43%;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 9%;
  pointer-events: auto;
  z-index: 10;
}

[data-component="message-modal"] button:hover::after {
  color: #384464;
  background-color:#ce9e24;
  box-shadow: none;
}
#ref_6_title{
  margin-top: 0.5%;
  width: 10vw;  
}
#ref_6_subtitle{
  font-size: 2.5vw;
  font-weight: bold;
  position: absolute;
  top: 11vw;
  background: transparent;
  z-index: 11;
}

#ref_6_p{
  font-size: 1.71875vw;
  width: 60vw;
  height: 30vw;
  justify-content: start;
  top: 16vw;
  position: fixed;
  background: transparent;
  font-family: Livvic;
  font-style: normal;
  font-weight: 400;
  line-height: 2.34375vw;
  letter-spacing: 0.05vw;
  text-align: center;
  z-index: 12;

}
#ref_6_button_div {
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 15%;
  z-index: 13;
}
#ref_6_button_div > p {
  box-sizing: border-box;
  width: 20vw;
  text-align: center;
  background-color: #F2EEE3;
  color: black;
  padding-left: 3vw;
  padding-right: 3vw;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 11vw;
  font-size: 1.4vw;
  padding-top: 2vw;
  padding-bottom: 2vw;
  border-radius: 2vw;
  margin-top: 3%;
  font-family: 'Livvic', sans-serif;
  font-weight: 400;
}
#ref_6_button_div > p > a {
  color:#E0964D;
  font-weight: 600;
  pointer-events: auto;
  text-decoration: none;
}

#ref_6_button_div > p > a:hover {
  text-decoration: none;
  pointer-events: visible;
  color:#384464
}
#rice {
  width: 20%;
  margin-top:1%;
}
#waterbottle {
  width: 3vw;
  margin-top: 1.5%;
}
#books-img {
  width: 10vw;
  margin-top: 1.5%;
}
#football{
  width: 9vw;
  margin-top: 2%;
}
#clock-img {
  width: 9vw;
  margin-top: 1%;
}
#ref_3_sub {
  font-family: Livvic;
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 400;
  line-height: 3.125vw;
  letter-spacing: 0.05vw;
  text-align: center;
  width: 50vw;
  padding: 0;
}
#ref_3_sub > p:nth-child(2) {
  margin-top: 3%;
}
