/* title screen */
[data-screen="main"] {
	justify-content: center;
}

[data-screen="main"] #julie-tboy-logo {
  align-content: center;
  position: absolute;
  width:100%;
  height:100%;
  opacity: 0;
  animation: fade-In 2s ease-in forwards;
}

@keyframes fade-In {
  to{
    opacity: 1;
  }
}

[data-screen="main"] #julie-tboy-logo img {
  width: 100%;
  height: 100%;
}

[data-component="main-menu"] audio {
  display:none;
}
[data-component="main-menu"] {
  position: fixed;
  right: 0;
  bottom: 0;
  margin-bottom: 4%;
  margin-right: 5%;
  width: 10vw;
}
[data-component="main-menu"] button {
  font-family: 'Baloo Da 2', cursive;
  text-transform: uppercase;
  border: hidden;
  opacity: 0;
  animation: fade-In 2s ease-in forwards;
  color: #FFEDAB;
  background: #364467;
  display: flex;
  flex-direction: row;
  border-radius: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5vh;
  width: 18vw;
  margin-bottom: 7%;
}

[data-component="main-menu"] img {
  position: static;
  width: 2vw;
  height: auto;
  fill:#FFEDAB
}

[data-component="main-menu"] span {
  font-weight: normal;
  font-size: 2vh;
  font-family: 'Baloo Da 2', cursive;
}

[data-component="main-menu"] button:hover {
  color: #364467;
  background-color:#FFEDAB;
  fill:#FFEDAB
}
/* [data-component="main-menu"] button:nth-child(1)::after {
  content:"START ADVENTURE"
}
[data-component="main-menu"] button:nth-child(3) {
  display:none;
}
[data-component="main-menu"] button:nth-child(4) {
  display:none;
} */

[data-component="mute-button"] button {
  position: fixed;
  top: 5%;
  right: 5%;
  background-color: #364467;
  border-color:#364467;
  border-radius: 50%;
  justify-content: center;
  align-content: center;
  display: flex;
  height: 5em;
  width: 5em;
  opacity: 0;
  animation: fade-In 2s ease-in forwards;
}
[data-component="mute-button"] button > img {
  align-self: center;
  height: 100%;
  width: 100%;
  margin-top: 8%;
}

