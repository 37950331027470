.chore-animation1 {
  /* top: 2% !important;
  left: -75% !important;
  margin: auto;
  bottom: unset !important;
  
  max-width: 25% !important;
  max-height: 30% !important; */
  width: fit-content;
  animation: slideInLeft forwards;
  animation-duration: 3s;
}

.chore-animation2 {
  /* top: 2% !important;
  left: -25% !important;
  margin: auto;
  bottom: unset !important;

  max-width: 25% !important;
  max-height: 30% !important; */
  width: fit-content;
  animation: slideInLeft forwards;
  animation-duration: 3s;
}

.chore-animation3 {
  /* top: 2% !important;
  left: 25% !important;
  bottom: unset !important;
  margin: auto;
  max-width: 25% !important;
  max-height: 30% !important; */
  width: fit-content;
  animation: slideInLeft forwards;
  animation-duration: 3s;
}

.chore-animation4 {
  /* top: 2% !important;
  left: 75% !important;
  bottom: unset !important;
  margin: auto;
  max-width: 25% !important;
  max-height: 30% !important; */
  width: fit-content;
  animation: slideInLeft forwards;
  animation-duration: 3s;
}

.chore-animation5 {
  /* top: 37% !important;
  left: -75% !important;
  bottom: unset !important;
  margin: auto;
  max-width: 25% !important;
  max-height: 30% !important; */
  width: fit-content;
  animation: slideInLeft forwards;
  animation-duration: 3s;
}

.chore-animation6 {
  /* top: 37% !important;
  left: -25% !important;
  bottom: unset !important;
  margin: auto;
  max-width: 25% !important;
  max-height: 30% !important; */
  width: fit-content;
  animation: slideInLeft forwards;
  animation-duration: 3s;
}

.chore-animation7 {
  /* top: 37% !important;
  left: 25% !important;
  bottom: unset !important;
  margin: auto;
  max-width: 25% !important;
  max-height: 30% !important; */
  width: fit-content;
  animation: slideInLeft forwards;
  animation-duration: 3s;
}

.chore-animation8 {
  /* top: 37% !important;
  left: 75% !important;
  bottom: unset !important;
  margin: auto;
  max-width: 25% !important;
  max-height: 30% !important; */
  width: fit-content;
  animation: slideInLeft forwards;
  animation-duration: 3s;
}