/* text box */
[data-component="text-box"] {
	display: flex;
	align-self: center;
	width: 95%;
	background: rgb(54, 68, 103, 0.8);
	border-radius: 16px;
  padding: 0.5vw;
  z-index: 103;
  min-height: 10vw;
  padding-bottom: 1.5vw;
}
[data-component="text-box"] p {
  position:relative;
  color:#F0EEE1;
  font-family: 'Livvic', sans-serif;
  font-size: 1.5vw;
  font-weight: 400;
  letter-spacing: 0.1vw;
  line-break: auto;
  padding-left: 2%;
  padding-top: 0.4%;
}

/* text-box.animated > div:nth-child(3) > p::after {
	content:"\f01"
} */

#text-icon {
  padding-top: 1%;
  padding-left: 0.5%;
  width: 1.3vw;
  height: auto;
}