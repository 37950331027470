@import "./styles/components.css";
@import "./styles/text-box.css";
@import "./styles/kid-background-ani.css";
@import "./styles/choices.css";
@import "./styles/message.css";
@import "./styles/title-screen.css";
@import "./styles/settings.css";
@import "./styles/save-screen.css";
@import "./styles/load-screen.css";
@import "./styles/credit-screen.css";
@import "./styles/quit.css";
@import "./styles/slotdeletion.css";
@import "./styles/chore-animation.css";
@import "./styles//splashscreen.css";
@import "./styles/pencil-animation.css";
@import "./styles/hand-animation.css";

.App {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  /* padding-top: 591.44px / 1127.34px * 100%;
  background: red;
  position: relative; */
}
body {
  background-color: #F0EEE1;
}
/* .Game {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */

[data-component="game-screen"] {
  align-content: center;
  margin-left: 11%;
  margin-right: 11%;
  margin-bottom: 2%;
  margin-top: 2%;
}

/* game background */
[data-screen].active {
  border-radius: 2%;
  height: auto;
}

@media screen and (max-width: 64em) {
  [data-component="game-screen"] {
    align-content: center;
    margin-left: 11%;
    margin-right: 11%;
    margin-bottom: 3%;
    margin-top: 2%;
  }
}

/* custom classes */
.fullsize {
  width: 100%;
  height:100%;
  margin:0;
}

.noanimation {
  animation:none;
  transition:none;
}

.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  animation:none !important;
}
.aspect-ratio--16x9 {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}
.aspect-ratio__inner-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* .transitionBackground1 {
  visibility: hidden;
  background-image:url("/src/1_7Aii.jpg");
  background-image:url("/src/1_7Aiii.jpg");
  background-image:url("/src/1_7Aiv.jpg");
  background-image:url("/src/1_7B.jpg");
  animation: changeBackground 2s linear forwards;
} 

@keyframes changeBackground {
  0%{
    visibility:visible;
    background-image:url("/src/1_7Aii.jpg");
  }
  45%{
    visibility:visible;
    background-image:url("/src/1_7Aiii.jpg");
  }
  75%{
    visibility:visible;
    background-image:url("/src/1_7Aiv.jpg");
  }
  100%{
    visibility:visible;
    background-image:url("/src/1_7B.jpg");
  }
} */

div#preload {
  display: none;
}

#root > div > main-mobile {
  display:none;
}

@media only screen and (max-width: 480px), (max-aspect-ratio: 1/0.8) {
  #root > div > main-mobile {
    display:flex;
    position:absolute;
    align-items: center;
    width: 100%;
    height: 100%;
    }
  #root > div > main-mobile > div {
    display: flex;
    padding: 64px 30px 60px 30px;
    width:100%;
    background:#F0EEE1;
    z-index: 100000;
    flex-direction: column;
  }
  #root > div > main-mobile > div > p {
    font-family: Livvic;
    color:#384464;
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 24px;
    font-weight: 600;
  }
}

