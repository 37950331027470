settings-screen.animated {
  font-family: 'Baloo Da 2', cursive;
  background-image: url('/src/setting-screen.png');
  color: #384464;
}

settings-screen.animated > h2:nth-child(2){
  text-align: start;
  margin-left: 5%;
  margin-top: 5%;
  font-size: 3vw;
  font-weight: 700;
}



.vertical{
  align-items: start;
  margin-left: 4%;
}

.vertical h3 {
  font-size: 3vw;
}

.vertical div span {
  font-size: 1.5vw;
  width: 10vw;
}

.text---center {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  align-content: flex-start;
  margin: 0%;
}

div.row__column:nth-child(2){
  text-align: start;
  margin-left: 4%;
}

div.row__column:nth-child(2) > div:nth-child(1) > h3:nth-child(1){
  font-size: 2vw;
}
div.row__column:nth-child(2) > div:nth-child(2) > h3:nth-child(1){
  font-size:2vw
}



.slideContainer {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 80%;
}
.slideContainer span{
  width: 155px;
  text-align: left;
}

input[type="range"]{
  width:40%;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #EEBA44;
  border-radius: 1.3px;
  border: 0.2px solid #EEBA44;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #EEBA44;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #EEBA44;
  border-radius: 1.3px;
  border: 0.2px solid #EEBA44;
}


input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #EEBA44;
  border: 0.2px solid #EEBA44;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]:focus::-ms-fill-lower {
  background: #EEBA44;
}
input[type=range]::-ms-fill-upper {
  background: #EEBA44;
  border: 0.2px solid #EEBA44;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]:focus::-ms-fill-upper {
  background: #EEBA44;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #EEBA44;
  height: 25px;
  width: 16px;
  border-radius: 16px;
  background: #EEBA44;
  cursor: pointer;
  margin-top: -8px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
}

input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #EEBA44, 0px 0px 1px #EEBA44;
  border: 1px solid #EEBA44;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #EEBA44;
  cursor: pointer;
}
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #EEBA44, 0px 0px 1px #EEBA44;
  border: 1px solid #EEBA44;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #EEBA44;
  cursor: pointer;
}
[data-screen]:not([data-screen="game"]) [data-action="back"] {
  bottom: 0;
  margin-left: 5%;
  width: 20%;
  background: transparent;
  color: #384464;
  border: hidden;
  justify-content: left;
  font-size: 1.5vw;
}

settings-screen.animated > button:nth-child(1):after {
  content: 'BACK TO GAME';
}
settings-screen.animated > button svg {
  display:none;
}
settings-screen.animated > button:nth-child(1):hover:after {
  font-size:1.8vw;
}