.pencil-animation {
    animation: pencil-animation 0.5s infinite;
    animation-direction: alternate;
}

@keyframes pencil-animation {
    0%{
        -webkit-transform: rotate(2deg);
        transform: rotate(2deg);
    }
    50%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    70%{
        -webkit-transform: rotate(1deg);
        transform: rotate(1deg);
    }
    100% {
        -webkit-transform: rotate(-2deg);
        transform: rotate(-2deg);
    } 
}