#splashscreen {
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  position: absolute;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeOut forwards;
  animation-duration: 3s;
  animation-delay: 5s;
  position: absolute;
}

#first-page {
  animation: fadeOut forwards;
  animation-duration: 3s;
  animation-delay: 2s;
  position: absolute;
}

#second-page {
  animation: fadeOut forwards;
  animation-duration: 3s;
  animation-delay: 6s;
  position: absolute;
}