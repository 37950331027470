[message="SlotDeletion"]  {
  background-color:rgb(57, 69, 101,0.6);
}

[message="SlotDeletion"]  > div:nth-child(1) {
  width: 40vw;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #F0EEE1;
  border-radius: 20px;
}

[message="SlotDeletion"]  > div:nth-child(1) > p:nth-child(1) {
  display: flex;
  color: #384464;
  font-size: 1vw;
  font-weight: 600;
  justify-content: center;
  text-align: center;
}

[message="SlotDeletion"] > div:nth-child(1) > small:nth-child(2) {
  font-size: 1vw;
  color: #384464;
  font-weight: 600;
  margin-bottom: 3%;
}

[message="SlotDeletion"] > div:nth-child(1) > div:nth-child(3) {
  display: flex;
  justify-content: space-evenly;
}

[message="SlotDeletion"] > div:nth-child(1) > div:nth-child(3) button {
  background:#FCD62F;
  border-radius: 42px;
  width: 11vw;
  height: 7vh;
  font-size: 1.5vw;
  color: #364467;
  border:hidden;
}

[message="SlotDeletion"] > div:nth-child(1) > div:nth-child(3) button:hover {
  color: #FCD62F;
  background-color:#364467;
  border:hidden;
}
