[data-component="sidebar-element"] #clock {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
}

[data-component="sidebar-element"] #clock > img {
  position: static;
  margin-top: 20%;
  width: 9vw;
  margin-left: 11%;
}

[data-component="quickmenu-element"] #sidemenu {
  position: fixed;
  right: 0;
  bottom: 0;
  margin-bottom: 2%;
  margin-right: 1%;
  width: 8vw;
}

[data-component="quickmenu-element"] button {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #828282;
  background-color: transparent;
  border: hidden;
  height: 100%;
  width: 2vw;
  border-radius: 16px;
}
[data-component="quickmenu-element"] img {
  position: static;
  width: 4vw;
  height: auto;
}
[data-component="quickmenu-element"] span {
  font-weight: bold;
  font-size: 2vh;
  font-family: 'Baloo Da 2', cursive;
}
[data-component="quickmenu-element"] button:hover {
  color: #364467;
}
[data-component="mute-ingame"] button {
  position: fixed;
  top: 5%;
  right: 5%;
  height: 6vh;
  background: transparent;
  border:hidden;
  display:none; /*temp*/
}

[data-component="mute-ingame"] button img{
  width: auto;
  height: 7vh;
  top: 0px;
  left: 0px;
  bottom: 0px;
}

#game-credit-p {
  right: 12vw;
  bottom: 0vw;
  position: fixed;
  font-weight: 600;
  color: #384464;
  display:none;
}

@keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;} 
} 
@-webkit-keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}