save-screen.animated {
  font-family: 'Baloo Da 2', cursive;
  background-image: url('/src/Load-Save-Background.jpg');
  color: #384464;
}
save-screen > div.horizontal.horizontal--center > input:nth-child(1){
  display:none;
}

save-screen > div.horizontal.horizontal--center  > input:nth-child(1):before{
  content:"";
}
save-screen > div.horizontal {
  display: flex;
  justify-content: left;
  margin-top: 3%;
  margin-left: 6%;
}
save-screen > div.horizontal:after{
  content:"Save Adventure";
  font-size: 3vw;
}
save-screen > div.horizontal.horizontal--center  > button:nth-child(2){
  position: absolute;
  bottom: 18%;
  left: 18%;
  height: 6vh;
  width: 9vw;
  box-shadow: inset 0px -7px 3px #FFEEA5;
  border-radius: 16px;
  background: #F8DD67;
  color: #AA7900;
  font-size: 1.2vw;
  text-transform: uppercase;
  z-index: 1;
  border:hidden;
}
save-screen.animated > div:nth-child(3) {
  margin-left: -1%;
  margin-top: 2%;
  width: 50%;
}
save-screen.animated > div:nth-child(3) > slot-container:nth-child(1) > save-slot {
  height: 23vh;
  float: left;
  width: 32%;
  margin-right: 5%;
  margin-bottom: 5%;
  border-radius: 20px;
}
/* save-screen.animated > div:nth-child(3) > slot-container:nth-child(1) > save-slot:hover {
  width: 50%;
  height: 30%;
  transition: ease-in-out 0.5s;
} */

save-screen.animated > div:nth-child(3) > slot-container:nth-child(1) > save-slot > div {
  height:100%;
  border-radius: 20px;
}

save-screen.animated > div:nth-child(3) > slot-container:nth-child(1) > save-slot > figcaption {
  display:none;
  }
  
  
  
  save-screen.animated > div:nth-child(3) > slot-container:nth-child(1) > save-slot > button {
    visibility: hidden;
    color: rgb(56, 68, 100, 0.5);
    font-size: 1vw;
    width: auto;
    left: 0;
    top:unset;
    bottom: -2vh;
  }
  
  save-screen.animated > div:nth-child(3) > slot-container:nth-child(1) > save-slot > button::after {
    visibility: visible;
    content:"Delete";
  }
  
  save-screen.animated > div:nth-child(3) > slot-container:nth-child(1) > save-slot > small {
    position: absolute;
    top: -12%;
    font-size: 1vw;
    background: transparent;
    color: #384464;
    font-weight: 600;
    font-style: normal;
  }


save-screen.animated > button:nth-child(1):after {
  content: 'BACK TO GAME';
}
save-screen.animated > button:nth-child(1):hover:after {
  font-size:1.8vw;
}
save-screen.animated > button svg {
  display:none;
}