
credit-screen.animated {
  overflow-y: auto;
  overflow-x: hidden;
  display: none;
  align-items: stretch;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}

credit-screen.animated.active {
  font-family: 'Baloo Da 2', cursive;
  background-image: url('/src/setting-screen.png');
  color: #384464;
  background-size: cover;
  width: 100%;
  height: auto;
  display: block;
}

credit-screen.animated.active button {
  color: #384464;
  border: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 42%;
  width: auto;
  background: transparent;
  font-size: 1.5vw;
  margin-bottom: 2%;
  z-index: 20;
}
credit-screen.animated.active button:hover {
  font-size:1.8vw;
  left:41%;
}

credit-screen.animated.active h2 {
  display: flex;
  flex-direction: row;
  height: 5vh;
  margin-top: 4%;
  margin-left: 4%;
  margin-bottom: 2%;
  font-weight: 700;
  font-size: 2vw;
}

credit-screen.animated.active div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 4%;
}

credit-screen.animated.active > div:nth-child(3) > h3 {
  font-size: 1.4vw;
  font-weight: 700;
  line-height: 5vh;
}
credit-screen.animated.active > div:nth-child(3) > div {
  margin:0%;
  margin-bottom: 2%;
  font-size: 1.4vw;
}

credit-screen.animated.active > div:nth-child(3) > div:nth-child(2) > p{
  margin-bottom: 1vh;
  font-size: 1vw;
}

credit-screen.animated.active > div:nth-child(3) > div:nth-child(4) > p{
  margin-bottom: 1vh;
  font-size: 1vw;
}

credit-screen.animated.active > div:nth-child(3) > div:nth-child(5) {
  display: flex;
  flex-direction: row;
  width: 30%;
  justify-content: flex-start;
  margin-left: -1.2%;
}

div.active.column {
  width: 40%;
}